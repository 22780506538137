<template>
  <div>
    <vue-country-code
      :default-country='defaultCountry'
      @onSelect='countrySelect'
      v-model='countryValue'
      :preferred-countries="['kr', 'us']">
    </vue-country-code>
  </div>
</template>

<script>
import VueCountryCode from 'vue-country-code'
export default {
  name: 'EditFormFieldCountry',
  components: {
    VueCountryCode,
  },
  props: [
    'metaData',
    'value',
  ],
  data () {
    return {
      countryValue: '',
      defaultCountry: '',
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.countryValue = newVal
        }
      }, 
      immediate: true,
    },
    countryValue: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:countryValue', newVal)
        }
      }, 
      immediate: true
    },
    preSelectedCountry: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.defaultCountry = newVal
          this.countryValue = newVal
        }
      },
      immediate: true,
    }
  },
  computed: {
    preSelectedCountry () {
      return this.$route.query.country ? this.$route.query.country : ''
    },
  },
  methods: {
    countrySelect ({iso2}) {
      this.countryValue = iso2
    },
  },
}
</script>
